<template>
  <div class="in-workbench">
    <el-row :gutter=20>
      <el-col :span=4>
        <el-button type="primary" class="btn-full" style="margin-top: 20px" @click="newMachine">新增otc变量名</el-button>
      </el-col>
    </el-row>
    <!-- 顶部操作栏结束，表格开始 -->
    <el-row :gutter=20>
      <el-col :span=24>
        <el-table :data="allMachine" border class="tableInWorkbench" height="calc(100vh - 180px)">
          <el-table-column prop="name" :min-width=150 label="机组名称"></el-table-column>
          <el-table-column prop="company" :min-width=120 label="所属公司"></el-table-column>
<!--          <el-table-column prop="serviceDep" :min-width=120 label="所属服务部"></el-table-column>-->
<!--          <el-table-column prop="centerStation" :min-width=120 label="所属中心站"></el-table-column>-->
          <el-table-column prop="station" :min-width=120 label="所属换热站"></el-table-column>
          <el-table-column prop="phone1" :min-width=120 label="otc名"></el-table-column>
<!--          <el-table-column prop="remark" :min-width=200 label="备注" :show-overflow-tooltip="true"></el-table-column>-->
          <el-table-column :resizable="false" align="center" :width=100 label="操作">
            <template slot-scope="scope">
              <el-link @click="editMachine(scope.row)">编辑</el-link>&nbsp;
              <el-link @click="deleteMachine(scope.row)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <template>
      <el-dialog title="新建otc变量名" :visible.sync="managementinfo" :append-to-body="true" width="50%"
                 top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false>
        <el-form>
          <el-row>
            <el-col :xs=24 :sm=12>
              <el-form-item label="机组名" label-width="80px">
                <el-input v-model="addmanagementinfo.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs=24 :sm=12>
              <el-form-item label="otc变量名" label-width="80px">
                <el-input v-model="addmanagementinfo.otcname"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureadd">确定新增</el-button>
        <el-button type="danger" @click="back">取消新增</el-button>
      </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import clientUtils from "../../js/clientUtils";
import serverMessages from "../../js/serverMessages";

export default {
  name: "otc",
  data() {
    return {
      allMachine: [],
      managementinfo: false,
      addmanagementinfo: {}
    }
  },
  methods: {
    newMachine: function () {
      this.managementinfo = true
    },
    back: function () {
      this.managementinfo = false
    },
    sureadd: function () {
      this.managementinfo =false
      clientUtils.post("", this.addmanagementinfo, function (res) {
        if (res != null&&res.data.data.code==200) {
          clientUtils.popSuccessMessage("新增otc成功")
        } else {
          clientUtils.popErrorMessage("新增otc失败")
        }
      })
    },
    editMachine:function (obj) {
      obj ==""//这里的obj就是对应那个行的对象
      this.managementinfo =true
    //  这个地方有些迷糊了按道理说编辑就是开弹窗的
    },
    deleteMachine:function (obj) {
      obj=="" //此处的obj同理
      const that = this;
      this.$confirm('确定要删除 ' + obj.name + ' 吗？', clientUtils.Tip, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        // 确定删除
        clientUtils.get('/system/machine/delete/' + obj.id, function (res) {
          if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
            // 删除成功
            that.loadAllMachine();
            clientUtils.popSuccessMessage('该otc变量已经被删除！')
          } else {
            // clientUtils.unknownErrorAlert(that, res.data.msg)
          }
        })
      }).catch(() => { })
    }
  },
  mounted() {
    clientUtils.get("", function (res) {
      const that = this
      if (res.data != null) {
        that.allMachine = res.data
      //  这里的allMachine就是获得的List<pojo>那个list对象
      //  获得这个list集合后list里面套的事对象将相应的对象名对应在prop上即可展示列表
      }

    })
  }
}
</script>

<style scoped>

</style>
